/* 
=================================================
             GENERIC CSS CLASSES
=================================================
*/
/*               MAIN CONTAINER
=================================================
*/
.main-conatiner-view {
  width: 100%;
  min-height: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  /* background-color: #ffffff; */
  /*DEBUG*/
  /* background-color: gray;  */
}

/*                 SECTIONS CONTAINER
=================================================
*/
.section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0px;
  /*DEBUG*/
  /* background-color: brown;  */
}
.section-area {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px 40px 40px 20px;
}
.section-title {
  width: 75%;
  padding: 20px 0px;
  text-align: center;
  font-size: 46px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* background-color: chocolate; */
}
.section-subtitle {
  width: 100%;
  padding: 20px 22px 40px 22px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 22px;
  text-align: center;

  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* background-color: brown; */
}
.section-area-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section-card {
  width: 580px;
  height: 237px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 33px;
  border-radius: 10px;
  box-shadow: 0px 6px 54px #00000059;
  background-color: #212121;
}
.section-card-icon {
  height: 64px;
}
.section-card-title {
  margin: 30px 0px 20px 0px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  /* background-color: darkorange; */
}
.section-card-subtitle {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  /* background-color: brown; */
}

.section-actions {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 25px 0px 70px 0px;
  /* background-color: darkgoldenrod; */
}

.section-flex-row {
  width: 100%;
  height: 100px;
}

.button.MuiButton-root {
  background-color: transparent;
  border: 1px solid #ffffff;
  margin: 0px 30px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
}

.MuiButton-root {
  background-color: transparent;
  border: 1px solid #ffffff;
  margin: 0px 30px;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
}

.button.MuiButton-root:hover {
  border: 1px solid rgba(170, 27, 37, 1);
}

.header-menu-container {
  display: none;
}

.header-menu-layer {
  display: none;
}

@media screen and (max-width: 950px) {
  .header-menu-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex;
    position: fixed;
    top: 70px;
    padding-bottom: 41px;
    background-color: #191919;
    overflow: hidden;
    z-index: 900;
    transition: top 0.3s linear;
  }
  .header-menu-layer {
    width: 100%;
    height: 100vh;
    display: initial;
    position: fixed;
    z-index: 890;
    background-color: rgb(0, 0, 0);
    transition: opacity 0.3s linear;
  }
}

.header-menu-container p {
  font-size: 17px;
  line-height: 19px;
  color: #ffffff;
  letter-spacing: 0.47px;
  margin: 0 13px;
  cursor: pointer;
  transition: all 0.1s linear;
}
.header-menu-container p:hover {
  color: rgba(170, 27, 37, 1);
  font-weight: bold;
}
.header-menu-container a {
  font-size: 17px;
  line-height: 19px;
  color: #ffffff;
  letter-spacing: 0.47px;
  margin: 0;
  cursor: pointer;
  transition: all 0.1s linear;
}
.header-menu-container a:hover {
  color: rgba(170, 27, 37, 1);
  font-weight: bold;
}

.header-menu-row {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background-color: orange; */
}

.registo-container-area {
  width: 100%;
  display: flex;
  margin-top: 50%;
  margin-bottom: 50%;
  justify-content: center;
  align-items: center;
  z-index: 0;
  /* background-color: brown; */
}

.registo-form-area {
  width: 50%;
  height: 100%;
  position: relative;
  padding-left: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: chocolate; */
}
.registo-form-area > .image-area-item {
  display: none;
}

.registo-image-area {
  width: 50%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  /* background-color: pink; */
}
.registo-image-area img {
  position: absolute;
}
.registo-image-area > .image-area-item:nth-child(1) {
  display: initial;
  z-index: 2;
  transform: translateX(-110px);
  /* left: 0px; */
  height: 600px;
}
.registo-image-area > .image-area-item:nth-child(2) {
  display: initial;
  z-index: 1;
  transform: translateX(110px);
  /* left: 220px; */
  height: 600px;
}
.registo-image-area > .image-area-item:nth-child(3) {
  display: initial;
  left: -60px;
  z-index: 0;
}
.registo-image-area > .image-area-item:nth-child(4) {
  z-index: 3;
  transform: translate(-230px, -235px);
}

.cf-card {
  max-width: 170px;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cf-card-title {
  padding: 10px 0px;
  text-align: center;
  font-size: 46px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.cf-card-subtitle {
  margin: 30px 0px 20px 0px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
}
.cf-card-description {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
}
.cf-card-icon {
  height: 64px;
  margin: 10px 0px 20px 0px;
}

.cf-arrow-container {
  width: 170px;
  height: 50px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.cf-arrow-container > img:nth-child(1) {
  display: none;
  position: absolute;
  top: 20px;
}
.cf-arrow-container > img:nth-child(2) {
  display: flex;
  position: absolute;
  top: 20px;
}

.pc-card {
  width: 296px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pc-card-title {
  width: 240px;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  position: relative;
  bottom: -20px;
  z-index: 3;
}
.pc-arrow-icon {
  width: 238px;
  position: relative;
}
.pc-arrow-icon > img {
  position: absolute;
  right: -20px;
  z-index: 5;
}
.pc-card-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.pc-card-qrcode > img:nth-child(2) {
  width: 200px;
  position: absolute;
  z-index: 2;
}

@media (min-width: 767px) and (max-width: 1200px) {
  .registo-image-area {
    justify-content: center;
  }
  .registo-image-area > .image-area-item:nth-child(1) {
    display: initial;
    transform: translateX(-0px);
  }
  .registo-image-area > .image-area-item:nth-child(2) {
    display: none;
  }
  .registo-image-area > .image-area-item:nth-child(3) {
    width: 100%;
    left: auto;
  }
  .registo-image-area > .image-area-item:nth-child(4) {
    transform: translate(-120px, -230px);
  }
  .registo-form-area {
    margin-left: 30px;
  }
}

@media (min-width: 374px) {

  .cf-card {
    max-width: 180px;
  }
}

@media (max-width: 767px) {


.registo-container-area {

  margin-top: 40%;
  margin-bottom: 50%;
  /* background-color: brown; */
}

  .mobile {
    height: 100% !important;
    margin-top: 50px !important;
    margin-bottom: 0px !important;
  }
  .section-title {
    font-size: 35px;
    /* margin-top: 25px; */
  }
  .registo-form-area {
    padding: 20px;
    width: 100vw;
    align-items: flex-start!important;
    justify-content: center!important;
  }
  .registo-form-area > .image-area-item {
    display: flex;
    position: absolute;
    transform: translate(130px, 0px);
  }
  .registo-image-area {
    display: none;
  }
  .section-area-wrap {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
  }
  .section-card {
    width: 100%;
    height: auto;
  }
  .cf-arrow-container > img:nth-child(1) {
    display: flex;
  }
  .cf-arrow-container > img:nth-child(2) {
    display: none;
  }
  .section-actions {
    flex-direction: column;
  }
}

.language-container {
  width: 60px;
  margin: 0px 25px 0px 5px;
  z-index: 10000;
}
.button-toogle-menu.MuiButton-root {
  background-color: transparent;
}
.button-toogle-menu.MuiButton-root:hover {
  background-color: transparent;
}
.menu {
  width: auto;
  margin-right: 4px;
}

.paper {
  background: #343434 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 3px #00000033 !important;
  border-radius: 4px !important;
  margin: 15px 0px !important;
}
.menu-item {
  color: #ffffff !important;
  padding: 10px 20px !important;
}
.menu-item:hover {
  background-color: rgba(170, 27, 37, 1) !important;
}

.registo-image-container {
  height: 100%;
  padding-left: 70px;
  /* background-color: orange, */
}

.section-actions .MuiButtonBase-root {
  margin-bottom: 15px !important;
}

.header-option-buttons {
  display:inline-block;
  text-align: center;
  background-color: pink;
  justify-content: center;
  align-items:center;
  padding: 0px 10px;
  margin: 0px 10px;
  position: relative;
}


li {
  display: inline-block;
  font-size: 0;
}
li a {
  display:inline-block;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin: 0 11px;
  transition: all 0.1s linear;
}
a:hover {
  font-weight:bold;
  color: rgba(170, 27, 37, 1);
  cursor: pointer;
}
a::before {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

